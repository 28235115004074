import { toFormData } from './form';
import { forceLogout } from 'store/user/actions'
import {
	setCaptchaKey,
	setCaptchaAction,
	setCaptchaToken
} from 'store/captcha/actions'
import store from 'store/store'
import { getHeaders } from './analytics'

export const requestDispatch = async (requestMethod: Function, afterRequest: Function | null = null) => {
	if (afterRequest) {
		store.dispatch(setCaptchaAction(afterRequest))
	}
	// @ts-ignore
	return await store.dispatch(requestMethod)
}

export const request = async (route: string, method:string = 'GET', data = {}, absRoute:string|null = null) => {
	const { captchaToken } = store.getState().captcha
	const { token } = store.getState().user
	const fd = toFormData(data)
	if (token) {
		fd.append('access_token', token)
	}
	if (captchaToken) {
		fd.append('_recaptcha_token', captchaToken)
		store.dispatch(setCaptchaToken(null))
	}
	try {
		const response: any = await fetch(absRoute ?? `https://api.gta5rp.com/${route}`, {
			method,
			body: fd,
			headers: getHeaders()
		})
		const responseData = await response.json()

		if (responseData.auth_error) {
			//@ts-ignore
			store.dispatch(forceLogout())
		}
		if (!responseData.success) {
			// eslint-disable-next-line no-throw-literal
			throw { 'error_message': responseData.error_text, 'response': responseData }
		}

		return responseData
	} catch ({ error_message, response }) {
		if (error_message === 'need_captcha') {
			response.error_text = 'Google ReCaptcha'
			store.dispatch(setCaptchaKey(response.key))
		}
		return response
	}
}
