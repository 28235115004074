import Cookies from 'js-cookie';

const getClientId = () => {
  const gaCookie = Cookies.get('_ga');

  if (!gaCookie) {
    return null;
  }

  const parts = gaCookie.split('.');

  return parts.length > 2 ? `${parts[2]}.${parts[3]}` : null;
};

const getSessionId = () => {
  const gaSessionCookieKey = Object.keys(Cookies.get()).find((key) => key.startsWith('_ga_'));

  if (!gaSessionCookieKey) {
    return null;
  }

  const gaSessionCookie = Cookies.get(gaSessionCookieKey);

  if (!gaSessionCookie) {
    return null;
  }

  const parts = gaSessionCookie.split('.');

  return parts.length > 5 ? parts[5] : null;
};

const getUtmParams = () => {
  const params = new URLSearchParams(window.location.search);

  return {
    source: params.get('utm_source'),
    campaign: params.get('utm_campaign'),
    content: params.get('utm_content'),
  };
};

const getAnalyticsOptions = () => ({
  clientId: getClientId(),
  sessionId: getSessionId(),
  utm: getUtmParams(),
});

const isAvailable = () => {
  const { clientId, sessionId } = getAnalyticsOptions();

  return Boolean(clientId && sessionId);
};

const getHeaders = () => {
  if (!isAvailable()) {
    return {
      'X-GMTC': '',
      'X-GMTS': '',
      // 'X-UTM': '',
    };
  }

  const { clientId, sessionId, utm } = getAnalyticsOptions();

  const assertedUtm =
    Boolean(utm.campaign) && Boolean(utm.content)
      ? `utm_campaign=${utm.campaign}&utm_content=${utm.content}`
      : '';

  return {
    'X-GMTC': clientId,
    'X-GMTS': sessionId,
    // 'X-UTM': assertedUtm,
  };
}

export { getHeaders };
